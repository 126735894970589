<template>
  <view-item title="客户积分">
    <template #operation>
      <en-button v-if="tabs.active === 'vehicle'" type="primary" @click="operation.exportVehicle.click">导出</en-button>

      <en-dropdown v-if="tabs.active === 'customer'" @command="operation.options.command">
        <en-button type="primary">操作</en-button>
        <template #dropdown>
          <en-dropdown-item v-if="tabs.active === 'customer'" command="sms">发送短信</en-dropdown-item>
          <en-dropdown-item v-if="tabs.active === 'customer'" command="sms-batch">全部发送短信</en-dropdown-item>
          <en-dropdown-item v-if="tabs.active === 'customer'" command="export">导出</en-dropdown-item>
        </template>
      </en-dropdown>
    </template>

    <en-card class="h-full" body-class="h-full">
      <flex-box>
        <template #default="{ height }">
          <en-tabs v-model="tabs.active">
            <en-tab-pane label="客户" name="customer">
              <table-dynamic
                code="CRWDPT"
                :data="customer.data"
                :loading="customer.loading"
                :height="height - 55"
                pagination
                :paging="customer.paging"
                :method="customer.get"
                show-selectable
                @selection-change="customer.selection.change"
              >
                <template #OPERATION="{ row }: { row: CustomerRewardPointDto }">
                  <en-button type="primary" link @click="customer.operation.adjust.click(row)">积分调整</en-button>
                  <en-button type="primary" link @click="customer.operation.history.click(row)">积分流水</en-button>
                </template>
              </table-dynamic>
            </en-tab-pane>
            <en-tab-pane label="车辆" name="vehicle">
              <table-dynamic
                code="VLCRWDPT"
                :data="vehicle.data"
                :loading="vehicle.loading"
                :height="height - 55"
                pagination
                :paging="vehicle.paging"
                :method="vehicle.get"
                show-selectable
                @selection-change="vehicle.selection.change"
              >
                <template #OPERATION="{ row }: { row: VehicleRewardPointQueryDto }">
                  <en-button type="primary" link @click="vehicle.operation.adjust.click(row)">积分调整</en-button>
                  <en-button type="primary" link @click="vehicle.operation.history.click(row)">积分流水</en-button>
                </template>
              </table-dynamic>
            </en-tab-pane>
          </en-tabs>
        </template>
      </flex-box>
    </en-card>
  </view-item>

  <en-drawer v-model="adjust.visible" title="积分调整">
    <en-form :model="adjust.form.data" :rules="adjust.form.rules" :ref="setRef('adjustForm')" :loading="adjust.form.loading">
      <en-form-item label="客户名称">{{ adjust.form.data.customer?.name }}</en-form-item>
      <en-form-item label="当前积分(分)">{{ adjust.form.data.remainingAmount }}</en-form-item>
      <en-form-item label="调整积分(分)">
        <en-input-number v-model="adjust.form.data.adjustedAmount" :min="-adjust.form.data.remainingAmount" class="w-full"></en-input-number>
      </en-form-item>
      <en-form-item label="调整后积分(分)">
        {{ Number(adjust.form.data.adjustedAmount) + Number(adjust.form.data.remainingAmount) }}
      </en-form-item>
      <en-form-item label="备注" prop="comment">
        <en-input v-model="adjust.form.data.comment" type="textarea"></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="adjust.footer.cancel.click">取消</en-button>
      <button-ajax type="primary" :method="adjust.footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>

  <en-drawer v-model="customerHistory.visible" title="积分记录" direction="btt" size="80%">
    <flex-box>
      <template #default="{ height }">
        <en-table
          :data="customerHistory.table.data"
          :height="height"
          :method="customerHistory.table.get"
          :paging="customerHistory.table.paging"
          pagination
          :loading="customerHistory.table.loading"
        >
          <en-table-column label="客户名称" prop="customer.name"></en-table-column>
          <en-table-column label="单据类型" prop="accountTransactionType.message"></en-table-column>
          <en-table-column label="单据编号" prop="documentSerialNo"></en-table-column>
          <en-table-column label="积分流水" prop="amount">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['AccountHistoryDto'] }">{{ formatMoney(row.amount) }}</template>
          </en-table-column>
          <en-table-column label="流水日期" prop="datetime">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['AccountHistoryDto'] }">{{ formatDate(row.datetime) }}</template>
          </en-table-column>
          <en-table-column label="流水备注" prop="comment"></en-table-column>
          <en-table-column label="操作人" prop="preparedBy.name"></en-table-column>
        </en-table>
      </template>
    </flex-box>

    <template #footer>
      <en-button @click="customerHistory.footer.cancel.click">取消</en-button>
    </template>
  </en-drawer>

  <en-drawer v-model="vehicleHistory.visible" title="积分记录" direction="btt" size="80%">
    <flex-box>
      <template #default="{ height }">
        <en-table
          :data="vehicleHistory.table.data"
          :height="height"
          :method="vehicleHistory.table.get"
          :paging="vehicleHistory.table.paging"
          pagination
          :loading="vehicleHistory.table.loading"
        >
          <en-table-column label="车牌号" prop="vehicle.plateNo"></en-table-column>
          <en-table-column label="单据类型" prop="type.message"></en-table-column>
          <en-table-column label="单据编号" prop="serialNo"></en-table-column>
          <en-table-column label="积分流水" prop="point"></en-table-column>
          <en-table-column label="流水日期" prop="datetime">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['AccountHistoryDto'] }">{{ formatDate(row.datetime) }}</template>
          </en-table-column>
          <en-table-column label="流水备注" prop="comment"></en-table-column>
          <en-table-column label="操作人" prop="preparedBy.name"></en-table-column>
        </en-table>
      </template>
    </flex-box>

    <template #footer>
      <en-button @click="vehicleHistory.footer.cancel.click">取消</en-button>
    </template>
  </en-drawer>

  <short-message-template v-model="sms.visible" :data="sms.data"></short-message-template>
</template>

<script lang="ts">
import { EnMessage } from '@enocloud/components'
import { ShortMessageTemplate } from '@enocloud/business'

type CustomerRewardPointDto = EnocloudCommonDefinitions['CustomerRewardPointDto']
type VehicleRewardPointQueryDto = EnocloudCommonDefinitions['VehicleRewardPointQueryDto']

export default factory({
  components: { ShortMessageTemplate },

  config: {
    children: {
      operation: {
        children: {
          exportCustomer: {
            ajax: {
              get: {
                action: 'GET /enocloud/common/customer/rewardpoint/export'
              }
            },
            async click() {
              await this.operation.exportCustomer.get()
              this.store.openDownload()
            }
          },
          exportVehicle: {
            ajax: {
              get: {
                action: 'GET /enocloud/common/vehicle/rewardpoint/export'
              }
            },
            async click() {
              await this.operation.exportVehicle.get()
              this.store.openDownload()
            }
          },
          options: {
            async command(option: string) {
              switch (option) {
                case 'sms':
                  if (!this.customer.selection.data.length) return EnMessage.warning('请选择客户')
                  this.sms.data = this.customer.selection.data
                  this.sms.visible = true
                  break
                case 'sms-batch':
                  this.sms.data = this.customer.data
                  this.sms.visible = true
                  break
                case 'export':
                  this.operation.exportCustomer.click()
                  break
              }
            }
          }
        }
      },
      tabs: {
        active: 'customer' as 'customer' | 'vehicle'
      },
      customer: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/customer/rewardpoint/query',
            summary: 'GET /enocloud/common/customer/rewardpoint/summary',
            data: 'array',
            loading: true,
            pagination: true
          }
        },
        children: {
          selection: {
            data: [] as CustomerRewardPointDto[],
            change(rows: CustomerRewardPointDto[]) {
              this.customer.selection.data = rows
            }
          },
          operation: {
            adjust: {
              async click(row: CustomerRewardPointDto) {
                const customer = await this.ajax('GET /enocloud/common/customer/:customerId', { paths: [row.customerId!] })
                const chargeable = await this.ajax('GET /enocloud/common/customer/chargeable/query', { payload: { accurateName: row.name } })
                this.adjust.form.by = 'customer'
                this.adjust.form.init()
                this.adjust.form.data.customer = customer.data[0]
                this.adjust.form.data.remainingAmount = chargeable.data[0]?.remainingAmount ?? 0
                this.adjust.visible = true
              }
            },
            history: {
              async click(row: CustomerRewardPointDto) {
                this.customerHistory.table.get({ payload: { accountTypeCode: 'RWDPNT', customerId: row.customerId } })
                this.customerHistory.visible = true
              }
            }
          }
        }
      },
      vehicle: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/vehicle/rewardpoint/query',
            summary: 'GET /enocloud/common/vehicle/rewardpoint/summary',
            data: 'array',
            loading: true,
            pagination: true
          }
        },
        children: {
          selection: {
            data: [] as VehicleRewardPointQueryDto[],
            change(rows: VehicleRewardPointQueryDto[]) {
              this.vehicle.selection.data = rows
            }
          },
          operation: {
            adjust: {
              async click(row: VehicleRewardPointQueryDto) {
                const vehicle = await this.ajax('GET /enocloud/common/vehicle/:vehicleId', { paths: [row.vehicleId!] })
                const chargeable = await this.ajax('GET /enocloud/common/customer/chargeable/query', { payload: { accurateName: row.customerName } })
                this.adjust.form.by = 'vehicle'
                this.adjust.form.init()
                this.adjust.form.data.customer = { name: row.customerName } as EnocloudCommonDefinitions['CustomerDto']
                this.adjust.form.data.vehicle = vehicle.data[0]
                this.adjust.form.data.remainingAmount = chargeable.data[0]?.remainingAmount ?? 0
                this.adjust.visible = true
              }
            },
            history: {
              async click(row: VehicleRewardPointQueryDto) {
                this.vehicleHistory.table.get({ paths: [row.vehicleId] })
                this.vehicleHistory.visible = true
              }
            }
          }
        }
      },
      adjust: {
        visible: false,
        children: {
          form: {
            by: '',
            data: { adjustedAmount: 0, remainingAmount: 0 } as EnocloudCommonDefinitions['AccountMemberAdjustDto'] & { remainingAmount: number },
            init() {
              this.adjust.form.data = { adjustedAmount: 0, remainingAmount: 0 } as EnocloudCommonDefinitions['AccountMemberAdjustDto'] & {
                remainingAmount: number
              }
            },
            rules: {
              adjustedAmount: [{ required: true, message: '请输入有效积分' }]
            },
            ajax: {
              submitByCustomer: {
                action: 'POST /enocloud/common/account/member',
                loading: true,
                params(params) {
                  params.payload = this.adjust.form.data
                }
              },
              submitByVehicle: {
                action: 'POST /enocloud/common/account/member/vehicle',
                loading: true,
                params(params) {
                  params.payload = this.adjust.form.data
                }
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.adjust.visible = false
              }
            },
            confirm: {
              async click() {
                if (this.adjust.form.by === 'customer') {
                  await this.adjust.form.submitByCustomer()
                  this.customer.get()
                } else if (this.adjust.form.by === 'vehicle') {
                  await this.adjust.form.submitByVehicle()
                  this.customer.get()
                }

                this.adjust.visible = false
              }
            }
          }
        }
      },
      customerHistory: {
        visible: false,
        children: {
          table: {
            ajax: {
              get: {
                action: 'GET /enocloud/common/customer/account',
                data: 'array',
                loading: true,
                pagination: true
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.customerHistory.visible = false
              }
            }
          }
        }
      },
      vehicleHistory: {
        visible: false,
        children: {
          table: {
            ajax: {
              get: {
                action: 'GET /enocloud/common/vehicle/:vehicleId/account/history',
                data: 'array',
                loading: true,
                pagination: true
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.vehicleHistory.visible = false
              }
            }
          }
        }
      },
      sms: {
        visible: false,
        data: [] as CustomerRewardPointDto[]
      }
    }
  },

  mounted() {
    this.customer.get()
    this.vehicle.get()
  }
})
</script>
